<template>
	<div class="dashboard-container">
		<router-link v-for="order in orders" :key="`order-${ order.id }`" :to="`/checkout/${ order.id }/complete`" class="order-history">
			<div class="location-photo">
				<img :src="order.location.banner_image_url || '/images/photos/coffee.jpg'">
			</div>
			<div class="location-info">
				<b>{{ order.location.business.name }}</b><br>
				{{ order.order_total | cents }}<br>
				<i>
					<date :date="order.created_at" format="MMMM DD, YYYY"></date>
				</i>
			</div>
			<div class="location-review">
				<rate-location :current-rating="order.rating" :location-id="order.location.id" @click.prevent="true"></rate-location>
			</div>
		</router-link>

		<div v-if="!orders.length" class="no-orders-yet"><p>Welcome to Fine Grounds! Ready to place your first
			order?</p>
			<router-link to="/browse/list">Bean Me Up, Scottie! <span class="fal fa-rocket"></span></router-link>
		</div>
	</div>
</template>

<script>

import RateLocation from '../account/components/RateLocation';

export default {

	name: 'OrderHistoryPage',

	async asyncData({ app, store }) {
		let orders = [];
		try {
			const { data } = await app.$api.get('/account/orders');
			orders = data.data;
		} catch(e) {
			store.dispatch('errorToast', 'Unable to load order history. Please try again later or contact support.');
		}

		return {
			orders,
		};
	},

	data() {
		return {
			orders: [],
		};
	},

	components: {
		RateLocation,
	},

};

</script>
